<template>
  <div>
    <img
      src="../assets/img/goback1.png"
      alt=""
      @click="onClickLeft"
      class="goback"
    />
    <div class="loginBox">
      <div class="accountTitle">
        <img src="../assets/img/image.png" alt="" />
        <ul>
          <li class="fontTitle">快乐星球</li>
          <li class="englishTitle">HAPPY STAR</li>
        </ul>
      </div>
      <p class="tableTitle">SING IN</p>
      <van-form @submit="onBlur">
        <van-cell-group inset class="cellTable">
          <van-cell value="Email" />
          <van-field
            v-model="addFrom.account"
            label=""
            placeholder=" Emali"
            clearable
            left-icon="envelop-o"
            border="true"
          />
          <van-cell value="Password" />
          <van-field
            v-model="addFrom.password"
            type="password"
            label=""
            placeholder=" Password"
            clearable
            border="true"
            left-icon="lock"
            maxlength="20"
            @update:model-value="changePassword"
          />
        </van-cell-group>

        <div class="clickViews">
          <van-field name="checkboxGroup" label="">
            <template #input>
              <van-checkbox
                v-model="checkedRule"
                icon-size="14px"
                class="checkbox"
                disabled="false"
              >
              </van-checkbox>
              <p class="checktitle" @click="protocolPrivacy">
                <i class="iBox"
                  >Use 8-16 characters with a mix of letters, numbers &
                  symbols</i
                >
              </p>
            </template>
          </van-field>
        </div>

        <div class="clickViews">
          <van-field name="checkboxGroup" label="">
            <template #input>
              <van-checkbox
                v-model="remember"
                icon-size="14px"
                class="checkbox"
                disabled="false"
              >
              </van-checkbox>
              <p class="checktitle" @click="protocolPrivacy">
                <i class="iBox">Must contain at least 2 types of characters</i>
              </p>
            </template>
          </van-field>
        </div>
      </van-form>
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        class="loginButton"
        @click="loginLine"
      >
        Create Account
      </van-button>
      <div class="clickViews">
        <van-field name="checkboxGroup" label="">
          <template #input>
            <van-checkbox v-model="checked" icon-size="14px" class="checkbox">
            </van-checkbox>
            <p class="checktitle">
              <i class="buttonBox" @click="protocolPrivacy"
                >views terms and agreements</i
              >
            </p>
          </template>
        </van-field>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import "vant/lib/button/style";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import service from "../utils/api";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";
import method from "../utils/codeJudgment";

export default {
  name: "loginSecond",
  props: {},
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      addFrom: [],
      checked: "",
      // Remember password
      checkedRule: "",
      remember: "",
    });
    // 密码验证规则
    const changePassword = (val) => {
      var a = /[A-Za-z]+/.test(val);
      var b = /[0-9]+/.test(val);
      var c = new RegExp(
        "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
      ).test(val);
      const count = a + b + c;
      count >= 2 ? (state.remember = true) : (state.remember = false);
      count >= 3 || (val.length >= 8 && val.length <= 16)
        ? (state.checkedRule = true)
        : (state.checkedRule = false);
    };

    // 提交注册
    const loginLine = () => {
      if (state.checked == null || state.checked == "") {
        Toast.fail("You need to read and agree to our Terms before logging in");
        return false;
      }
      const aa =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (
        !state.addFrom.account ||
        !state.addFrom.password ||
        !aa.test(state.addFrom.account)
      ) {
        Toast.fail("Enter the correct email password");
      } else {
        getEmail();
      }
    };
    // 获取验证码
    const getEmail = () => {
      // 调用接口，判断用户
      service.getQCode({ account: state.addFrom.account }).then((res) => {
        if (res.data.errcode != 0) {
          Toast.fail(method.getTextByStatus(res.data.errcode));
        } else {
          router.push({
            path: "/registration",
            query: {
              account: state.addFrom.account,
              password: state.addFrom.password,
            },
          });
        }
      });
    };
    // 协议指南
    const protocolPrivacy = () => {
      router.push({ path: "/agreement" });
    };
    const onClickLeft = () => {
      router.push({ path: "/loginSecond" });
    };
    onMounted(() => {});

    return {
      ...toRefs(state),
      protocolPrivacy,
      changePassword,
      loginLine,
      getEmail,
      onClickLeft,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .goback {
    position: absolute;
    background-color: transparent;
    top: 6%;
    left: 7%;
    width: 35px;
    z-index: 1;
  }
  // 整体表单
  .loginBox {
    // background-color: #000511;
    background-color: transparent;
    z-index: 2;
    position: absolute;
    width: 37%;
    height: 100%;
    overflow: hidden;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    padding: 5% 30px 13.8% 0px;
    color: #ffffff;
    // 快乐星球盒子
    .accountTitle {
      height: 12%;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      overflow: hidden;
      img {
        width: 40px;
        height: 100%;
      }
      .englishTitle {
        font-size: 10px;
      }
    }
    // SING IN
    .tableTitle {
      text-align: left;
      font-size: 20px;
    }
    // 表单背景色
    .cellTable {
      background-color: transparent !important;
      .van-cell {
        font-size: 10px;
        overflow: hidden;
        padding-left: 6px;
      }

      // 输入表单的背景色
      .van-field {
        background-image: url("../assets/img/password.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 30px;
        line-height: 30px;
      }
    }
    // 阅读指南
    .clickViews {
      height: auto;
      padding: 0px;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 5px 0;
      .checktitle {
        width: 205px;
        color: grey;
        line-height: 10px;
        font-size: 10px;
      }
      .buttonBox {
        color: grey;
      }
    }
    // 登录按钮
    .loginButton {
      width: 100%;
      height: 40px;
      background-color: transparent;
      background-image: url("../assets/img/account.png");
      background-size: 100%;
      background-repeat: no-repeat;
      border: none;
      margin-top: 8px;
    }
  }
}
</style>
